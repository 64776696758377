<template>
  <div class="app">
    <h2>权力和义务</h2>
    <p>
      为了共同建立良好的、可持续的交流环境，飞跃网站赋予浏览的同学们相当的权力，也需要同学们尽到相应的义务：
    </p>
    <ol>
      <li>
        <p>
          每一位访客都需要通过统一身份认证登录进入网站，以确保是科大在校学生、老师或者已毕业的校友。
        </p>
      </li>
      <li>
        <p>
          每一位成功登陆的访客（以下简称“您”）有权查看飞跃网站中公开发布的信息和内容，包括但不限于历年申请结果、内容发布者公开的个人信息、Wiki文章等。
        </p>
      </li>
      <li>
        <p>
          您有权将飞跃网站中的公开信息用于个人用途，包括但不限于摘录部分文字、自行整理分析往年申请数据、联系相关校友等。您有义务保护这些信息不外泄、不用于商业用途，如发现信息泄露的情况，飞跃网站将采取强硬措施。
        </p>
      </li>
      <li>
        <p>
          您有权创建暑研/留学申请案例并分享您认为有价值的经验和信息，也有权在发布后进行修改。请注意，当您发布这些案例时，代表您自愿将这一部分信息分享给飞跃网站以及网站的所有用户。
        </p>
      </li>
      <li>
        <p>
          您在撰写和发布案例时，有义务遵守中华人民共和国相关法律法规，不得包含色情低俗、政治敏感、暴力恐怖等内容，不得发布虚假信息，不得以任何形式擅自发布广告。飞跃网站保留审核、修改和删除用户创建信息的权力，如发现您存在违规违法行为，将对您采取必要的措施，包括但不限于禁言、封号等等。
        </p>
      </li>
      <li>
        <p>
          飞跃网站不承诺任何用户发表的内容的真实性，请您理性看待、辩证分析。如发现虚假错误信息，您有权联系我们进行举报。
        </p>
      </li>
      <li>
        <p>
          您有权针对飞跃网站中不足之处向我们提出意见和建议，我们也将不断提升飞跃网站的全面性，为您提供一个更加方便好用的平台。
        </p>
      </li>
    </ol>
    <p></p>
  </div>
</template>

<script>
export default {
  name: "Rules",
};
</script>

<style scoped>
.app {
  margin: 0 auto;
  width: 900px;
}
</style>
